<template>
	<section class="py-4 bg-main text-white mb-2">
		<div class="container">
			<h2 class="font-weight-bold mb-3 text-center">Játékszabályzat</h2>
			<ol>
				<li>Csakis a játékszabályzat elfogadása után küldheted be a szavazatodat.</li>
				<li>A szavazatod elküldésekor rögzítjük az IP címedet, valamint a számítógéped egyedi azonosítóit.</li>
				<li>A beérkezett szavazatokat felülbírálás után kerülnek csak elfogadásra.</li>
				<li>Csakis magyarországi IP címekről érkező szavazókat áll módunkban elfogadni.</li>
				<li>Egy neptun azonosítóval, <strong>csakis egyszer</strong> lehet szavazni!</li>
				<li>Más neptun azonosítójával való visszaélést nem honoráljuk.</li>
			</ol>
		</div>
	</section>
</template>

<template>
  <div class="my-4">
    <h1 class="mb-3 font-weight-bold">Játékszabályzat/Nyereményjáték</h1>
    <p>
      Az Óbudai Egyetem kabalafigurájának tervezésére és gyártási specifikációjára kiírt ötletpályázat közönségdíját a legtöbb hallgatói szavazatot kapott pályamű szerzője kapja. A
      szavazásban résztvevő hallgatók (a továbbiakban: Játékos) között az Óbudai Egyetem által felajánlott, 5 darab „ÓE ajándékcsomagot” sorsol ki a pályázatot bonyolító Hallgatói
      Szolgáltatások Osztály (a továbbiakban: Szervező) az alábbi feltételek szerint:
    </p>
    <ol>
      <li>A játék időtartama: <strong>2021. február 15. – 2021. február 26.</strong></li>
      <li>
        A sorsolás ideje: <strong>2021. március 1.</strong><br />
        A sorsolás véletlengenerátor alkalmazásával, számítógépes program segítségével történik.
      </li>
      <li>
        Nyeremények: A kabalafigura.uni-obuda.hu oldalon megtalálható, nyereményjáték menüpontban feltüntetett tárgyi ajándékok. <br />Egy Játékos 1 db nyeremény megnyerésére
        jogosult.
      </li>
      <li>
        A felajánlott Nyeremények megnyerésének, vagyis a sorsoláson való részvételnek a feltétele a kabalafigura.uni-obuda.hu oldalon keresztül történő szavazás. Egy Játékos egy
        szavazat leadására jogosult.
      </li>
      <li>
        A kabalafigura.uni-obuda.hu oldalon keresztül történő szavazásban és ezzel együtt járó Nyeremények kiosztásában kizárólag az Óbudai Egyetemmel jogviszonyban álló hallgatók
        vehetnek részt.
      </li>
      <li>
        A nyereményjátékban való részvételre nem jogosultak:
        <ul>
          <li>a Szervező munkavállalói, valamint</li>
          <li>a jelen szavazás és a kapcsolódó nyereményjáték előkészítésében és lebonyolításában közreműködők.</li>
        </ul>
      </li>
      <li>
        A Szervező kabalafigura.uni-obuda.hu oldalon elérhető adatkezelési tájékoztató útján nyújt tájékoztatást a Játékos számára a jelen nyereményjátékkal kapcsolatban
        megvalósuló személyesadat-kezelésről. Ezen adatkezelési tájékoztató megismeréséről a szavazata leadásakor a Játékos nyilatkozik.
      </li>
      <li>
        A Játékos elfogadja, hogy kisorsolása esetén a Neptun tanulmányi rendszerben tárolt email címére írásbeli tájékoztatást kapjon a pályázatot bonyolító Hallgatói
        Szolgáltatások Osztály munkatársától.
      </li>
      <li>
        A Játékos elfogadja, hogy kisorsolása esetén a neve — a Nyereménnyel kapcsolatban — megjelenhet a kabalafigura.uni-obuda.hu oldalon, valamint az Óbudai Egyetem által kezelt
        más kommunikációs felületeken.
      </li>
      <li>
        A Játékos elfogadja, hogy nyereménye kézbesítése az e-mailes kapcsolatfelvétel során megadott postacímre történik. A kézbesítéshez szükséges adatokat az Egyetem továbbítja
        a kézbesítést végző szolgáltatónak. A szolgáltató személyéről az Egyetem a nyeremény átadásával kapcsolatos egyeztetések során nyújt az érintettnek tájékoztatást.
      </li>
      <li>
        Az általa megadott kézbesítési adatok helyessége, valamint a megadott kézbesítési címre érkező levelek figyelemmel követése és a nyeremény átvétele a Játékos felelőssége. A
        Nyereményekkel kapcsolatos bármilyen probléma esetén a Nyertes az Óbudai Egyetem Hallgatói Szolgáltatások Osztályához fordulhat a <strong>hszo@uni-obuda.hu</strong> e-mail
        címen.
      </li>
      <li>
        A Nyerteseket a Szervező a 2. pontban tartandó sorsolást követő 1 naptári napon belül e- mailben értesíti. A Nyertesek neve továbbá olvasható kabalafigura.uni-obuda.hu
        oldalon a nyereményjáték menüpontban. A Nyertesek az e-mailen történt értesítését követő 2 naptári napon belül kötelesek visszajelezni a Szervező részére a
        <strong>hszo@uni-obuda.hu</strong> e-mail címen, hogy a Nyereményt átveszi-e. Amennyiben átvételi igényét 2 naptári napon belül nem jelzi, illetőleg nem adja meg a
        kézbesítéshez szükséges postacímet, akkor új Nyertes kerül kisorsolásra.
      </li>
      <li>
        A Szervezők érdekkörén kívül felmerülő technikai problémákért (e-mail cím változása, hibás e-mail cím stb.), a Nyeremények kézbesítésének a Nyertesnek felróható
        elmaradásáért vagy késedelméért a Szervezők a Nyertes felé semmiféle felelősséget nem vállalnak.
      </li>
      <li>
        A Nyertest a megadott Neptun kód, valamint a Neptun rendszerben tárolt e-mail cím azonosítja. A sorsolást követően a Szervező a Nyertessel történő kapcsolatfelvétel céljára
        a Neptun rendszerben tárolt e-mail címet használja
      </li>
    </ol>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Footer,
  },
};
</script>

<style scoped>
li {
  margin: 1em 0em;
}
</style>
